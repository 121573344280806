<template>
  <el-dialog
    v-model="showDialog"
    class="messageBox-dialog"
    :class="{ withoutImg: !type }"
    :show-close="false"
    destroy-on-close
    :close-on-click-modal="false"
    :close-on-press-escape="false">
    <template #header v-if="type">
      <span class="dialog-header">
        <img
          v-if="type == 'warning'"
          src="@/assets/images/master-img-warn.png" />
        <img
          v-if="type == 'error'"
          src="@/assets/images/master-img-error.png" />
        <img
          v-if="type == 'success'"
          src="@/assets/images/master-img-success.png" />
      </span>
    </template>
    <div class="dialog-body">
      <p class="title" v-html="title"></p>
      <p v-if="text" class="description" v-html="text"></p>
    </div>
    <template #footer>
      <span class="dialog-footer">
        <EleButton
          v-if="showCancelBtn"
          type="solid"
          width="100%"
          @click="handleAction('cancel')"
          >{{ cancelBtnText }}</EleButton
        >
        <EleButton
          width="100%"
          :disabled="countdown > 0"
          @click="handleAction('confirm')"
          >{{ confirmBtnText }}
          <span v-if="countdown > 0">({{ countdown }})</span></EleButton
        >
      </span>
    </template>
  </el-dialog>
</template>

<script lang="ts">
import { App, defineComponent, ref } from 'vue'
import EleButton from '@/components/common/EleButton.vue'

export default defineComponent({
  components: {
    EleButton,
  },
  props: {
    text: { type: String, default: '' },
    title: { type: String, default: '' },
    cancelBtnText: { type: String, default: '取消' },
    showCancelBtn: { type: Boolean, default: true },
    confirmBtnText: { type: String, default: '確認' },
    type: { type: String, default: null }, // 'warning','error', 'success'
    countdownSeconds: { type: Number, default: 0 },
    resolve: Function,
    reject: Function,
  },
  data() {
    return {
      showDialog: true,
      promise: null as Promise<any> | null,
      countdown: 0,
    }
  },
  mounted() {
    // console.log('this.countdownSeconds', this.countdownSeconds)
    // 如需顯示倒數計時
    if (this.countdownSeconds && this.countdownSeconds > 0) {
      this.countdown = this.countdownSeconds
      let countdownInterval = setInterval(() => {
        if (this.countdown === 0) {
          clearInterval(countdownInterval)
        } else {
          // 否则，减少倒计时
          this.countdown--
        }
      }, 1000)
    }
  },
  computed: {},
  methods: {
    /** 處理動作 */
    handleAction(action: string) {
      this.showDialog = false
      if (action === 'confirm' && this.resolve) {
        // 確認時, 將 promise 設為 resolve 狀態
        this.resolve('confirm')
      }
      if (action === 'cancel' && this.reject) {
        // 取消時, 將 promise 設為 reject 狀態
        this.reject('cancel')
      }
    },
    // 顯示對話框, 對創建 promise 物件
    confirm() {
      this.showDialog = true
    },
  },
})
</script>
<style lang="scss" scoped>
.dialog-header {
  display: flex;
  justify-content: center;
  img {
    width: 280px;
  }
}
.dialog-body {
  .title {
    font-weight: 500;
    font-size: 20px;
    line-height: 32px;
    text-align: center;
    color: $black;
  }
  .description {
    padding-top: 4px;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: $black80;
  }
}
.dialog-footer {
  display: flex;
  align-items: center;
  gap: 12px;
}
</style>
<style lang="scss">
.el-dialog.messageBox-dialog {
  position: relative;
  width: 100%;
  max-width: 400px;
  height: auto;
  background: $white;
  margin: 20px;
  overflow: hidden;
  box-shadow: 0px -2px 20px rgba(0, 0, 0, 0.12);
  border-radius: 16px;
  &.withoutImg {
    .el-dialog__header {
      padding: 24px 20px 0;
    }
  }
  .el-dialog__header {
    padding: 24px 20px;
  }
  .el-dialog__body {
    overflow-y: auto;
    max-height: calc(100% - 56px);
    padding: 0 20px;
    // @include md-breakpoint {
    //   padding-bottom: 80px;
    // }
  }
  .el-dialog__footer {
    padding-top: 24px;
  }
}
</style>
